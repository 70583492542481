import axios from 'axios';

// Crie uma instância do Axios com a URL base da sua API
const api = axios.create({
  baseURL: 'https://api.minhaconta.click', // Substitua pela URL base da sua API
  withCredentials: true
});

// Defina as configurações padrão para todas as requisições
api.defaults.headers.common['Content-Type'] = 'application/json';

// Defina aqui qualquer lógica adicional que você precisa para as requisições

export default api;
