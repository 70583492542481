import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios'; // Importe o axios
import './LoginAccount.css'; // Importando o arquivo de estilo
import api from '../../services/api';

const LoginAccount: React.FC = () => {
  const [email, setEmail] = useState('');
  const history = useHistory();
  const logo = require('../../images/logo.gif');
  const [error, setError] = useState<string | null>(null);

  const handleConsultar = async () => {
    localStorage.setItem('userProductIds', '');
    localStorage.setItem('userInfo', '');

    // Validar o e-mail usando uma expressão regular simples
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setError('Por favor, insira um e-mail válido.');
      return;
    }

    setError(null);

    try {
      // Fazer a chamada para a API /search-client com o email informado
      const response = await api.get(`/search-client/${email}`);
      const { produto, user } = response.data;
    
      // Se o usuário possui produtos, armazenar os IDs no localStorage
      if (produto && produto.length > 0) {
        localStorage.setItem('userProductIds', JSON.stringify(produto));
        localStorage.setItem('userInfo', JSON.stringify(user.products));
      }
      // Redirecionar para MinhaConta
      history.push('/minha-conta');
    } catch (error) {
      console.error('Erro ao consultar cliente:', error);
      // Lógica para lidar com erros de consulta
    }
  };

  return (
    <div className="login-container">
      <div className="logo">
        <img src={logo} alt="Foguetinho" width="250" height="250" />
      </div>
      <div className="text">Informe seu Email</div>
      <input
        className="email-input"
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {error && <div className="error-message">{error}</div>}
      <button className="entrar-button" onClick={handleConsultar}>
        Entrar
      </button>
      
    </div>
  );
};

export default LoginAccount;
